<template>
	<div class="pumpkins">
		<NuxtImg
			class="decor"
			src="/nuxt-img/magic-boxes/magic-pumpkins/pumpkin-decor.png"
			:width="951"
			:height="411"
			format="webp"
			loading="lazy"
			alt="pumpkin"
		/>
		<MMagicBoxesSkinPumpkinAnimation :number="1" class="right" />
		<MMagicBoxesSkinPumpkinAnimation :number="3" class="center" />
		<MMagicBoxesSkinPumpkinAnimation :number="2" class="left" />
	</div>
</template>

<style scoped lang="scss">
.pumpkins {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 500px;
	.decor {
		position: relative;
		z-index: 2;
		width: 100%;
		height: auto;
	}
	.pumpkin {
		position: absolute;
		&::after {
			content: "";
			position: absolute;
			left: -10%;
			bottom: 1%;
			width: 120%;
			height: 15%;
			border-radius: 50%;
			background: radial-gradient(50% 50% at 50% 50%, #000 0%, rgba(205, 11, 165, 0.6) 80%, transparent 100%);
			box-shadow: 0 0 30px #cd0ba5;
			filter: blur(4px);
		}
	}
	.right {
		z-index: 0;
		width: 29%;
		top: 10%;
		left: 2.8%;
	}
	.center {
		z-index: 3;
		width: 36%;
		top: 4%;
		left: 32%;
	}
	.left {
		z-index: 0;
		width: 29%;
		top: 6%;
		right: 2%;
		&::after {
			bottom: 8%;
		}
	}
}
</style>
